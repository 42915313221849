import React from 'react';

import './intro.css';

import Photo1 from './images/photo1.jpeg';
import Photo2 from './images/photo2.jpeg';

const INTRO_ACTIVATION_FLOOR = 0;
const INTRO_ACTIVATION_HEIGHT = 3.5;

const INTRO_SCROLL_OFF_START = 2.5;

const PHOTO_SHOW_SCROLL_FLOOR = 0.01;
const PHOTO_CHANGE_SCROLL_POS = 2.7;

const INTRO_TEXT = "I'm the nice fella on the left, a software developer residing in Brasilia/Brazil looking for new opportunities while working on some passion projects";
const INTRO_TEXT_SCROLL_START = 0.01
const INTRO_TEXT_SCROLL_END = 2.01;

class Intro extends React.Component{

	constructor(props){
		super(props);
	
		this.state = {
			
			visibilityStatus: "visible",

			paralaxNoticeVisibility: "inherit",
			
			introTop: 0,

			//photo states
			photoVisibility: "hidden",
			photoSrc: Photo1,
			photoTop: window.innerHeight/2 - window.innerWidth/8,
			
			//intro text states
			introTextVisibility: "hidden",
			introTextSubstringAux: 0,
		}

		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}
	componentWillUnmount(){
		window.removeEventListener('scroll',this.handleScroll);
	}

	handleScroll(){
		
		let relativePageOffset = (window.pageYOffset/window.outerHeight) - INTRO_ACTIVATION_FLOOR;
		
		if((relativePageOffset < 0)||(relativePageOffset > INTRO_ACTIVATION_HEIGHT)){
			if(this.state.visibilityStatus !== "hidden"){
				this.setState({
					visibilityStatus: "hidden",
				});
			}
		}else{
			if(this.state.visibilityStatus !== "inherit"){
                this.setState({
					visibilityStatus: "inherit",
                }); 
            }  
			//Components visibility logic
			
			if(relativePageOffset < PHOTO_SHOW_SCROLL_FLOOR){
				if(this.state.photoVisibility !== "hidden"){
					this.setState({
						photoVisibility: "hidden",
					});
				}
				if(this.state.paralaxNoticeVisibility !== "inherit"){
					this.setState({
						paralaxNoticeVisibility: "inherit",
					});
				}
				if(this.state.introTextVisibility !== "hidden"){
	                this.setState({
	                    introTextVisibility: "hidden",
	                });
	            }
			}else{
				if(this.state.photoVisibility !== "inherit"){
	            	this.setState({
	                	photoVisibility: "inherit",
	                });
	            }
				if(this.state.paralaxNoticeVisibility !== "hidden"){
	                this.setState({
	                    paralaxNoticeVisibility: "hidden",
                    });
                }
	            if(this.state.introTextVisibility !== "inherit"){
	                this.setState({
	            		introTextVisibility: "inherit",
	                });
	            }
			}
			
			//Intro Top position logic
			
			if((relativePageOffset > INTRO_SCROLL_OFF_START) && (relativePageOffset <= INTRO_ACTIVATION_HEIGHT)){
				this.setState({
					introTop: -((relativePageOffset - INTRO_SCROLL_OFF_START)*window.innerHeight),
				})
			}else{
				if(this.state.introTop !== 0){
					this.setState({
						introTop: 0,
					})
				}
			}

			//My photo logic

			if(relativePageOffset < PHOTO_CHANGE_SCROLL_POS){
				this.setState({
					photoSrc: Photo1,
				});
			}else{
				this.setState({
					photoSrc: Photo2,
				});
			}

			if(this.state.photoTop !== window.innerHeight/2 - window.innerWidth/8){
				this.setState({
					photoTop: window.innerHeight/2 - window.innerWidth/8,
				});
			}

			//Intro text logic
			
			if(relativePageOffset < INTRO_TEXT_SCROLL_START){
				if(this.state.introTextSubstringAux !== 0){
					this.setState({
						introTextSubstringAux: 0,
					});
				}
			}else if((relativePageOffset >= INTRO_TEXT_SCROLL_START) && (relativePageOffset <= INTRO_TEXT_SCROLL_END)){
				this.setState({
					introTextSubstringAux: ((INTRO_TEXT.length) * ((relativePageOffset - INTRO_TEXT_SCROLL_START)/(INTRO_TEXT_SCROLL_END - INTRO_TEXT_SCROLL_START))),					
				});
			}else {
				if(this.state.introTextSubstringAux !== INTRO_TEXT.length){
					this.setState({
						introTextSubstringAux: INTRO_TEXT.length,
					});
				}
			}

		}
	}

	render(){
		return(
			<section className="intro" style={{top: this.state.introTop, 
											   visibility: this.state.visibilityStatus}}>
				<p className="paralaxNotice"
					style={{visibility: this.state.paralaxNoticeVisibility}}
					>
					This website uses parallax for a sense of movement.<br/>
					To navigate it keep scrolling down.<br/>
					This project is a work in progress as I learn React.<br/>
					There is a performance issue on Windows on the first time the website scrolls to the end.<br/>
					It is probably due to a lazy loading mechanism that does not preemptively load hidden components.<br/>
					Works fine on Linux, but will fix the performance issue on Windows in the future.<br/>
					</p> 
				<img className="introPhoto"
					 src={this.state.photoSrc}
					 alt=""
					 style={{top: this.state.photoTop + "px", visibility: this.state.photoVisibility}} />
				<div className="introText"
					style={{visibility: this.state.introTextVisibility}} >
					{INTRO_TEXT.substring(0,this.state.introTextSubstringAux)}
				</div>
			</section>
		)
	}
}

export default Intro;

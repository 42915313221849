import React from 'react';

import './cv.css';

const CV_ACTIVATION_FLOOR = 2.7;
const CV_ACTIVATION_HEIGHT = 5.3;

const SUPERIOR_ED_DIV_START = 0;
const SUPERIOR_ED_DIV_FULL_OPACITY = 0.5;
const SUPERIOR_ED_DIV_START_SCROLL_OFF = 1;
const SUPERIOR_ED_DIV_END = 1.5;

const WORK_EXP_START = 0.7;
const WORK_EXP_TITLE_SCROLL_ON_END = 1.2;
const WORK_EXP_BODY_SCROLL_START = 1.2;
const WORK_EXP_TITLE_SCROLL_OFF_START = 2;
const WORK_EXP_BODY_SCROLL_END = 2.2;
const WORK_EXP_END = 2.5;

const SKILLS_CERTS_START = 1.7;
const SKILLS_CERTS_TITLE_SCROLL_ON_END = 2.2;
const SKILLS_CERTS_BODY_SCROLL_START = 1.9;
const SKILLS_CERTS_TITLE_SCROLL_OFF_START = 3.9;
const SKILLS_CERTS_BODY_SCROLL_END = 4.2;
const SKILLS_CERTS_END = 4.4;

const CURRENT_PROJECTS_START = 3.7;
const CURRENT_PROJECTS_SCROLL_ON_END = 4.2;
const CURRENT_PROJECTS_FADE_OUT_START = 4.5;
const CURRENT_PROJECTS_END = 5;

class Cv extends React.Component{

	constructor(props){
		super(props);

		this.state = {
			
			visibilityStatus: "hidden",

			//Superior education Div
			
			superiorEducationDivVisibility: 'hidden',
			superiorEducationDivOpacity: 0,
			superiorEducationDivLeftOffset: 0,

			//Work Experience
			
			workExperienceVisibility: 'hidden',
			workExperienceTitleLeftOffset: 100,

			workExperienceBodyTop: 0,

			//Skills and Certs

			skillsAndCertsVisibility: 'hidden',
			skillsAndCertsTitleLeftOffset: -100,

			skillsAndCertsBodyTop: 0,

			//Current projects
			
			currentProjectsVisibility: 'hidden',
			currentProjectsLeftOffset: 100,
			currentProjectsTopOffset: 100,
			currentProjectsOpacity: 1,
		}
	
		this.workExpBodyRef = React.createRef();
		this.skillsAndCertsBodyRef = React.createRef();

		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(){
        let relativePageOffset = (window.pageYOffset/window.outerHeight) - CV_ACTIVATION_FLOOR;

		if((relativePageOffset < 0)||(relativePageOffset > CV_ACTIVATION_HEIGHT)){
            if(this.state.visibilityStatus !== "hidden"){
                this.setState({
                    visibilityStatus: "hidden",
                });
            }
        }else{
            if(this.state.visibilityStatus !== "inherit"){
                this.setState({
                    visibilityStatus: "inherit",
                });
            }

			//Superior education div logic

			if(relativePageOffset >= SUPERIOR_ED_DIV_START && relativePageOffset <= SUPERIOR_ED_DIV_END){
				if(this.state.superiorEducationDivVisibility !== 'inherit'){
					this.setState({
						superiorEducationDivVisibility: 'inherit',
					});
				}
			}else{
				if(this.state.superiorEducationDivVisibility !== 'hidden'){
	                this.setState({
	                    superiorEducationDivVisibility: 'hidden',
	                });
	            }
			}
			
			if(relativePageOffset >= SUPERIOR_ED_DIV_START && relativePageOffset <= SUPERIOR_ED_DIV_FULL_OPACITY){
				this.setState({
					superiorEducationDivOpacity: (relativePageOffset - SUPERIOR_ED_DIV_START)/(SUPERIOR_ED_DIV_FULL_OPACITY - SUPERIOR_ED_DIV_START),
				});
			}else{
				if(this.state.superiorEducationDivOpacity !== 1){
					this.setState({
    	                superiorEducationDivOpacity: 1,
        	        });
				}
			}

			if(relativePageOffset >= SUPERIOR_ED_DIV_START_SCROLL_OFF && relativePageOffset <= SUPERIOR_ED_DIV_END){
				this.setState({
					superiorEducationDivLeftOffset: -100*(relativePageOffset - SUPERIOR_ED_DIV_START_SCROLL_OFF)/(SUPERIOR_ED_DIV_END - SUPERIOR_ED_DIV_START_SCROLL_OFF),
				})
			}else if(relativePageOffset < SUPERIOR_ED_DIV_START_SCROLL_OFF){
				if(this.state.superiorEducationDivLeftOffset !== 0){
					this.setState({
						superiorEducationDivLeftOffset: 0,
					});
				}
			}else{
                if(this.state.superiorEducationDivLeftOffset !== -100){
                    this.setState({
                        superiorEducationDivLeftOffset: -100,
                    });
                }
			}

			//Work experience logic
			
            if(relativePageOffset >= WORK_EXP_START && relativePageOffset <= WORK_EXP_END){
                if(this.state.workExperienceVisibility !== 'inherit'){
                    this.setState({
                        workExperienceVisibility: 'inherit',
                    });
                }
            }else{
                if(this.state.workExperienceVisibility !== 'hidden'){
                    this.setState({
                        workExperienceVisibility: 'hidden',
                    });
                }
            }


			//Work experience title logic

			if((relativePageOffset < WORK_EXP_START)||(relativePageOffset > WORK_EXP_END)){
	            if(this.state.workExperienceTitleLeftOffset !== 100){
	                this.setState({
	                    workExperienceTitleLeftOffset: 100,
	                });
	            }
			}else if(relativePageOffset >= WORK_EXP_START && relativePageOffset <= WORK_EXP_TITLE_SCROLL_ON_END){
                this.setState({
                	workExperienceTitleLeftOffset: 100-100*(relativePageOffset - WORK_EXP_START)/(WORK_EXP_TITLE_SCROLL_ON_END - WORK_EXP_START),
				})
            }else if(relativePageOffset >= WORK_EXP_TITLE_SCROLL_OFF_START && relativePageOffset <= WORK_EXP_END){
                this.setState({
                    workExperienceTitleLeftOffset: 100*(relativePageOffset - WORK_EXP_TITLE_SCROLL_OFF_START)/(WORK_EXP_END - WORK_EXP_TITLE_SCROLL_OFF_START),
                })
			}else{
	            if(this.state.workExperienceTitleLeftOffset !== 0){
                    this.setState({
                        workExperienceTitleLeftOffset: 0,
                    });
                }
			}
		
			//Work experience body logic
			
			if(relativePageOffset < WORK_EXP_BODY_SCROLL_START){
				if(this.state.workExperienceBodyTop !== -(this.workExpBodyRef.current.clientHeight)){
					this.setState({
                    	workExperienceBodyTop: -(this.workExpBodyRef.current.clientHeight),
            	    })
				}
			}else if((relativePageOffset >= WORK_EXP_BODY_SCROLL_START) && (relativePageOffset <= WORK_EXP_BODY_SCROLL_END)){
				this.setState({
					workExperienceBodyTop: -(this.workExpBodyRef.current.clientHeight) +(this.workExpBodyRef.current.clientHeight+window.innerHeight)*((relativePageOffset - WORK_EXP_BODY_SCROLL_START)/(WORK_EXP_BODY_SCROLL_END - WORK_EXP_BODY_SCROLL_START)),
				})
			}else{
	            if(this.state.workExperienceBodyTop !== (window.innerHeight)){
                    this.setState({
                        workExperienceBodyTop: window.innerHeight,
                    })
                }
			}
			
			//Skills and certs logic
				
            if(relativePageOffset >= SKILLS_CERTS_START && relativePageOffset <= SKILLS_CERTS_END){
                if(this.state.skillsAndCertsVisibility !== 'inherit'){
                    this.setState({
                        skillsAndCertsVisibility: 'inherit',
                    });
                }
            }else{
                if(this.state.skillsAndCertsVisibility !== 'hidden'){
                    this.setState({
                        skillsAndCertsVisibility: 'hidden',
                    });
                }
            }

			//Skills and certs title logic

			if((relativePageOffset < SKILLS_CERTS_START) || (relativePageOffset > SKILLS_CERTS_END)){
                if(this.state.skillsAndCertsTitleLeftOffset !== -100){
                    this.setState({
                        skillsAndCertsTitleLeftOffset: -100,
                    });
                }
            }else if(relativePageOffset >= SKILLS_CERTS_START && relativePageOffset <= SKILLS_CERTS_TITLE_SCROLL_ON_END){
                this.setState({
                    skillsAndCertsTitleLeftOffset: -100 + 100*(relativePageOffset - SKILLS_CERTS_START)/(SKILLS_CERTS_TITLE_SCROLL_ON_END - SKILLS_CERTS_START),
                })
            }else if(relativePageOffset >= SKILLS_CERTS_TITLE_SCROLL_OFF_START && relativePageOffset <= SKILLS_CERTS_END){
                this.setState({
                    skillsAndCertsTitleLeftOffset: -100*(relativePageOffset - SKILLS_CERTS_TITLE_SCROLL_OFF_START)/(SKILLS_CERTS_END - SKILLS_CERTS_TITLE_SCROLL_OFF_START),
                })
            }else{
            	if(this.state.skillsAndCertsTitleLeftOffset !== 0){
                    this.setState({
                        skillsAndCertsTitleLeftOffset: 0,
                    });
                }
            }

			//Skills and certs body logic
            
			if(relativePageOffset < SKILLS_CERTS_BODY_SCROLL_START){
                if(this.state.skillsAndCertsBodyTop !== window.innerHeight){
                    this.setState({
                        skillsAndCertsBodyTop: (window.innerHeight),
                    })
                }
            }else if((relativePageOffset >= SKILLS_CERTS_BODY_SCROLL_START) && (relativePageOffset <= SKILLS_CERTS_BODY_SCROLL_END)){
                this.setState({
                    skillsAndCertsBodyTop: (window.innerHeight)-((this.skillsAndCertsBodyRef.current.clientHeight+window.innerHeight)*(relativePageOffset - SKILLS_CERTS_BODY_SCROLL_START)/(SKILLS_CERTS_BODY_SCROLL_END - SKILLS_CERTS_BODY_SCROLL_START)),
                })
            }else{
                if(this.state.skillsAndCertsBodyTop !== (this.skillsAndCertsBodyRef.current.clientHeight)){
                    this.setState({
                        skillsAndCertsBodyTop: (this.skillsAndCertsBodyRef.current.clientHeight),
                    })
                }
            }

			//Current projects logic
			
			if(relativePageOffset >= CURRENT_PROJECTS_START && relativePageOffset <= CURRENT_PROJECTS_END){
	            if(this.state.currentProjectsVisibility !== 'inherit'){
                    this.setState({
		                currentProjectsVisibility: 'inherit',
					});
				}
	        }else{
				if(this.state.currentProjectsVisibility !== 'hidden'){
	                this.setState({
	                    currentProjectsVisibility: 'hidden',
	                });
	            }
	        }

			if((relativePageOffset < CURRENT_PROJECTS_START)||(relativePageOffset > CURRENT_PROJECTS_END)){
				if((this.state.currentProjectsLeftOffset !== 100)||(this.state.currentProjectsTopOffset !== 100)){
                    this.setState({
                        currentProjectsLeftOffset: 100,
						currentProjectsTopOffset: 100,
                    });
                }
            }else if(relativePageOffset >= CURRENT_PROJECTS_START && relativePageOffset <= CURRENT_PROJECTS_SCROLL_ON_END){
                this.setState({
	                currentProjectsLeftOffset: 100-100*(relativePageOffset - CURRENT_PROJECTS_START)/(CURRENT_PROJECTS_SCROLL_ON_END - CURRENT_PROJECTS_START),
					currentProjectsTopOffset: 100-100*(relativePageOffset - CURRENT_PROJECTS_START)/(CURRENT_PROJECTS_SCROLL_ON_END - CURRENT_PROJECTS_START),
                })
            }else{
                if((this.state.currentProjectsLeftOffset !== 0)||(this.state.currentProjectsTopOffset !== 100)){
                    this.setState({
                        currentProjectsLeftOffset: 0,
						currentProjectsTopOffset: 0,
                    });
                }
            }

            if(relativePageOffset >= CURRENT_PROJECTS_FADE_OUT_START && relativePageOffset <= CURRENT_PROJECTS_END){
        		this.setState({
                	currentProjectsOpacity: (CURRENT_PROJECTS_END-relativePageOffset)/(CURRENT_PROJECTS_END-CURRENT_PROJECTS_FADE_OUT_START),
                });
			}else{
                if(this.state.currentProjectsOpacity !== 1){
                    this.setState({
                        currentProjectsOpacity: 1,
                    });
                }
            }

		}
	}

	render(){
		return(
			<section className="cv" style={{visibility: this.state.visibilityStatus}}>
				<div className="superiorEducationDiv" style={{visibility: this.state.superiorEducationDivVisibility,
															  opacity: this.state.superiorEducationDivOpacity,
															  left: (this.state.superiorEducationDivLeftOffset + 25)+"%"}}>
					<p className="pCenterText">
						Superior Education:<br/>
						<br/>
						Computer Science Bachelor<br/>
						UNB (University of Brasilia)<br/>
					</p>
				</div>
				<div className="workExperienceTitle" style={{visibility: this.state.workExperienceVisibility,
															 left: (this.state.workExperienceTitleLeftOffset + 50)+"%"}}>
					<p className="pCenterText">
						Work Experience
					</p>
				</div>
				<div className="workExperienceBody" ref={this.workExpBodyRef}
													style={{visibility: this.state.workExperienceVisibility,
															top: this.state.workExperienceBodyTop}}>
					<p className="pCenterText">
						2017-2018<br/>
						Chamber of Deputies, Brasilia<br/>
						(Brazil's House of Representatives)<br/>
						position: IT Intern<br/><br/>
						Responsible for the development of a proof of concept mobile software suite for the internal library systems. Implemented on Ionic and cordova framework using typescript.
						<br/>-----<br/>
						2012-2014<br/>
						TJDFT (local court of Federal District), Brasilia<br/><br/>
						position: IT Intern<br/>
						Responsible for Tier 2 user support and Emission of digital certificates.
 						<br/>-----<br/>
						2008-2009<br/>
						Digital inclusion course for seniors on UNB<br/>
						position: Volunteer Instructor<br/><br/>
						Course organized by the pedagogy departament.<br/>
					</p>
				</div>
				<div className="skillsAndCertsTitle" style={{visibility: this.state.skillsAndCertsVisibility,
													 		 left: (this.state.skillsAndCertsTitleLeftOffset)+"%"}}>
					<p className="pCenterText">
						Skills and Certifications
					</p>
				</div>
                <div className="skillsAndCertsBody" ref={this.skillsAndCertsBodyRef}
                                                    style={{visibility: this.state.skillsAndCertsVisibility,
                                                    top: this.state.skillsAndCertsBodyTop}}>
                    <p className="pCenterText">
						Assembly and installation<br/>
						of computer systems.<br/>
						<br/>
						Offered by C.E.U.B.<br/>
						(Centro Universitario de Brasilia)<br/>
						2007<br/>
						<br/>
						----------<br/>
						<br/>
						Certification TOEFL ITP - English - IsF<br/>
						<br/>
						Oral comprehension: 68<br/>
						(Max possible : 68)<br/>
						<br/>
						Grammar: 63<br/>
						(Max possible: 68)<br/>
						<br/>
						Text comprehension: 60<br/>
						(Max possible : 68)<br/>
						<br/>
						Total Score: 637<br/>
						(Max possible : 677)<br/>
						<br/>
						Results:<br/>
						QCE: C1<br/>
						( Max possible for this  test : C1)<br/>
						Proficient User, Effective Operational<br/>
						<br/>
                        Certification TOEFL ITP - English - IsF<br/>
						<br/>
						----------<br/>
						<br/>
						Programing Languages<br/>
						<br/>
						C, C++<br/>
						Javascript, Typescript<br/>
						Java<br/>
						Python 2.7 - 3.5<br/>
						<br/>
						----------<br/>
						<br/>
						Frameworks<br/>
						<br/>
						Ionic<br/>
						Cordova<br/>
						<br/>
						----------<br/>
						<br/>
						Hardware platforms<br/>
						<br/>
						Raspberry PI<br/>
						Arduino<br/>
						Attiny85<br/>
					</p>
                </div>
				<div className="currentProjects" style={{visibility: this.state.currentProjectsVisibility,
														 left: (this.state.currentProjectsLeftOffset+15)+"%",
														 top: (this.state.currentProjectsTopOffset+15)+"%",
														 opacity: this.state.currentProjectsOpacity}}>
					<p className="pCenterText">
						What I'm working on these days<br/>
						<br/>
						----------<br/>
						<br/>
						At the moment I’m focusing on a low cost converter of sound-waves to haptic feedback prototype that started as my graduation thesis, and finishing the credits needed for a Math degree
					</p>
				</div>
			</section>
		)
	}

}

export default Cv;

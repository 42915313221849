import React from 'react';
import './App.css';

import OrientationAlert from './components/orientationAlert/orientationAlert.js';
import ContentContainer from './components/contentContainer/contentContainer.js';

function App() {
  	return (
    	<div className="app">
			<section className="background" />
  			
			<OrientationAlert/>
			<ContentContainer/>
		</div>
	
	);
}

export default App;

import React from 'react';

import './contactInfo.css';

const CONTACT_INFO_ACTIVATION_FLOOR = 7.5;
const CONTACT_INFO_ACTIVATION_HEIGHT = 1;

const BACKGROUND_FADE_IN_START = 0;
const BACKGROUND_FADE_IN_END = 0.5;

class ContactInfo extends React.Component{

	constructor(props){
		super(props);
	
		this.state = {
			visibilityStatus: "hidden",

			backGroundOpacity: 0,
		}

		this.handleScroll = this.handleScroll.bind(this);
	}

	componentDidMount(){
		window.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(){
		let relativePageOffset = (window.pageYOffset/window.outerHeight) - CONTACT_INFO_ACTIVATION_FLOOR;

        if((relativePageOffset < 0)||(relativePageOffset > CONTACT_INFO_ACTIVATION_HEIGHT)){
            if(this.state.visibilityStatus !== "hidden"){
                this.setState({
                    visibilityStatus: "hidden",
                });
            }
        }else{
            if(this.state.visibilityStatus !== "inherit"){
                this.setState({
                    visibilityStatus: "inherit",
                });
            }
            if(relativePageOffset >= BACKGROUND_FADE_IN_START && relativePageOffset <= BACKGROUND_FADE_IN_END){
                this.setState({
                    backGroundOpacity: 0.5*(relativePageOffset - BACKGROUND_FADE_IN_START)/(BACKGROUND_FADE_IN_END - BACKGROUND_FADE_IN_START),
                });
            }else{
                if(this.state.backGroundOpacity !== 0.5){
                    this.setState({
                        backGroundOpacity: 0.5,
                    });
                }
            }
		}
	}

	render(){
		return(
			<section className="contactInfo" style={{visibility: this.state.visibilityStatus}}>
					<section className="backGround" style={{opacity: this.state.backGroundOpacity}}>
						<section className="topLeft" style={{}}>
							<p className="pContactsText">
								Contact Info:
							</p>
						</section>
                   		<section className="topRight" style={{}}>
							<p className="pContactsText">
								Email:<br/>
								titoklautau@gmail.com<br/>
								<br/>
								Tel:<br/>
								[+55](61)98293-7640
							</p>
                  		</section>
                    	<section className="botLeft" style={{}}>
							<p className="pContactsText">
								Repository and CV:
							</p>
                    	</section>
                    	<section className="botRight" style={{}}>
							<p className="pContactsText">
                        		Repository: bitbucket.org/TMKlautau/<br/>
                     						github.com/TMKlautau<br/>
								<br/>
								CV for download: <a href="https://drive.google.com/file/d/1DBrb3OMgze27J-mBgGR3aZtbviNkUMHd/view?usp=sharing">LINK</a>
                    		</p>
                    	</section>
					</section>
			</section>
		)
	}
}

export default ContactInfo;

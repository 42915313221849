import React from 'react';

import './orientationAlert.css';

import splashImage from './images/orientationAlertSplashImage.png';

class OrientationAlert extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			visibility: "visible",
		}
		this.checkActivation = this.checkActivation.bind(this);
	}

	componentDidMount(){
		window.addEventListener('resize', this.checkActivation);
		this.checkActivation();
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.checkActivation);
	}

	checkActivation(){
		if(window.innerWidth < window.innerHeight){
			if(this.state.visibility !== "visible"){
				this.setState({
					visibility: "visible", 
				});
			}
		}else if(this.state.visibilitty !== "hidden"){
			this.setState({
				visibility: "hidden",
			});
		}
	}

	render(){
		return(
			<section className="orientationAlert" style={{visibility: this.state.visibility}}>
				<img className="splashImage" src={splashImage} alt=""/>	
				<p className="alertText">Please Rotate Your Device</p>
			</section>
		)
	}

}

export default OrientationAlert;

import React from 'react';

import './contentContainer.css';

import Intro from './intro/intro.js';
import Cv from './cv/cv.js';
import ContactInfo from './contactInfo/contactInfo.js';

const CONTENT_SCREEN_HEIGHT = 9.2;

class ContentContainer extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			visibility: "hidden",
			loadFlag: false,
			scrollHeight: 0,
		}
		this.handleResize = this.handleResize.bind(this);
		//this.forceFullLoad = this.forceFullLoad.bind(this);
	}
	
	componentDidMount(){
		this.setState({
			loadFlag: true,
		}, () => {
			window.addEventListener('resize', this.handleResize);
			this.handleResize();
			//setTimeout(()=>(this.forceFullLoad()),0);
		});
	}

	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize(){
		
		window.scrollTo(0,((window.pageYOffset/this.state.scrollHeight)*(window.outerHeight*CONTENT_SCREEN_HEIGHT)));
		
		this.setState({
			scrollHeight: window.outerHeight * CONTENT_SCREEN_HEIGHT,
		});
		if(window.outerWidth < window.outerHeight){
	        if(this.state.visibility !== "hidden"){
	            this.setState({
	                 visibility: "hidden",
                });
            }
        }else if((this.state.visibilitty !== "visible")&&(this.state.loadFlag === true)){
	        this.setState({
	             visibility: "visible",
	        });
	    }
	}
	
	//forceFullLoad(){
	//	window.scrollTo({top:window.outerHeight*CONTENT_SCREEN_HEIGHT, left:0, behavior: 'smooth'});
	//}

	render(){
		return(
			<section className="contentContainer"
					 style={{visibility: this.state.visibility, height: this.state.scrollHeight}}>
					<Intro/>
					<Cv/>
					<ContactInfo/>
			</section>
		)
	}
}

export default ContentContainer;
